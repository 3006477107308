import _block from './_blockPracticalInformation'
import photoSchema from './photo'
import mediaSchema from './media'

const schema = {
  endpoint: '/api/practical_informations',
  clone_endpoint: '/api/practical_informations/clone',
  access: 'practicalInformation',
  resource: {
    definition: '#PracticalInformation',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          shortDescription: {
            description: 'Opis',
            label: 'Description',
            type: 'textarea',
            hint: 'opis na liście ofert'
          },
          description: {
            description: 'Opis',
            label: 'Description',
            type: 'tinymce',
          },

          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
          slug: {},
          preview: {
            label: 'Podgląd',
            description: '',
            type: 'preview',
            routes: {
              pl: '/oferta-specjalna/:slug',
              en: '/en/offer-special/:slug',
            },
          },
        },
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/practical_information_thumbs',
          property: 'media',
        },
      },
      // mediaThumbType: {
      //   type: 'resource',
      //   endpoint: `/api/thumb_types?pagination=false`,
      //   titleAccessor: `trans`,
      //   transTitle: true,
      //   label: 'T_GENERAL_MEDIA_PHOTO_TYPE',
      //   initialValue: { value: '/api/thumb_types/CROPPED', title: 'Kadr' },
      //   validate: ['required'],
      // },
      // hideMediaInDetail: {
      //   description: 'T_GENERAL_MEDIA_HIDE_IN_DETAIL',
      // },
      // mediaDetail: {
      //   type: 'image',
      //   description: 'T_GENERAL_PHOTO_DETAIL',
      //   endpoint: photoSchema.endpoint.single,
      //   validate: ['maxSize'],
      //   thumbs: {
      //     endpoint: '/api/practical_information_thumbs',
      //     property: 'mediaDetail',
      //   },
      // },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/practical_information_blocks',
      definition: '#PracticalInformationBlock',
      types: _block(
        mediaSchema.endpoint,
        '/api/practical_information_block_thumbs',
        '/api/practical_information_block_multi_sections',
        '/api/practical_information_block_multi_section_thumbs',
        '/api/practical_information_block_branches',
        '/api/practical_information_block_didascalias',
        '/api/practical_information_block_contacts'
      ).types,
    },
  },
}

export default schema
