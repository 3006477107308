import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { notification } from '_helpers/notification'
import { Loader } from 'components/Loader'
import { LANGS } from '_lib/langs'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { Alert } from '@material-ui/lab'

export const PreviewType = ({
  name,
  routes,
  label,
  hint = null,
  initialValue,
  value,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  translationLang,
  fullWidth = false,
  lang = null,
  getValue,
}) => {
  const [slug, setSlug] = React.useState(null)

  const [state, setState] = React.useState({
    isFetching: false,
  })

  const handlePreview = url => {
    setState({
      isFetching: true,
    })

    fetchDataHandleAuthError(
      `/preview${url}`,
      'GET',
      {},
      response => {
        setState({
          isFetching: false,
        })
        window.open(
          `${process.env.REACT_APP_BACKEND_ENTRYPOINT}/${response.token}`,
          '_blank'
        )
      },
      error => {
        setState({
          isFetching: false,
        })

        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  useEffect(() => {
    const t = getValue('translations')
    if (t && t[translationLang]) {
      setSlug(t[translationLang].slug)
    }
  }, [value, lang, translationLang, getValue])

  return (
    <>
      {slug && (
        <>
          <Button
            startIcon={state.isFetching ? <Loader /> : <VisibilityIcon />}
            variant="contained"
            color="primary"
            size="small"
            disabled={state.isFetching}
            onClick={() => {
              handlePreview(
                routes[LANGS[translationLang]].replace(':slug', slug)
              )
            }}
          >
            {label}
          </Button>
          <Alert severity="warning" style={{ background: 'none', padding: 0 }}>
            kesz podgladu wygasa, po kazdej zmianie danych należy kliknąc w
            guzik
          </Alert>
        </>
      )}
    </>
  )
}

PreviewType.propTypes = {}
