import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import schema from '_schema/eventTerm'
import { makeStyles } from '@material-ui/styles'
import routesEvent from 'pages/Event/routes'
import routesSpectacle from 'pages/Event/routesSpectacle'
import routesEventArchive from 'pages/EventArchive/routes'
import routesSpectacleArchive from 'pages/EventArchive/routesSpectacle'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment-timezone'
import 'moment/locale/pl'

// import 'react-big-calendar/lib/sass/styles.scss'
require('react-big-calendar/lib/css/react-big-calendar.css')

const useStyles = makeStyles(theme => ({
  legend_span: {
    display: 'inline-block',
    width: 12,
    height: 12,
    marginRight: 5,
    marginLeft: 10,
  },
  legend: {
    margin: '20px 0 0',
    border: '1px dashed gray',
    backgroundColor: 'rgba(255, 255, 255, .7)',
  },
}))

const localizer = momentLocalizer(moment)

export const Collection = () => {
  let history = useHistory()
  const colorState = {
    1: 'gray',
    2: 'lightgray',
    3: 'yellow',
    event: 'lightgreen',
    spectacle: 'lightblue',
  }
  // const colorDay = {
  //   1: 'lightyellow',
  //   2: 'salmon',
  //   3: 'red',
  // }

  const routesTo = {
    spectacle: { true: routesSpectacleArchive(), false: routesSpectacle() },
    event: { true: routesEventArchive(), false: routesEvent() },
  }

  const classes = useStyles()
  const events = [
    // {
    //   id: 1,
    //   title: 'Long Event',
    //   start: new Date(2024, 11, 27),
    //   end: new Date(2024, 11, 29),
    //   color: 'red',
    // },
    // {
    //   id: 2,
    //   title: 'DTS STARTS',
    //   start: new Date(2024, 11, 23, 0, 0, 0),
    //   end: new Date(2024, 11, 23, 10, 0, 0),
    //   color: 'blue',
    // },
  ]

  const [eventsList, setEventsList] = useState(events)
  // const [eventsBackgroundList, setEventsBackgroundList] = useState([])

  function onRangeChange({ start, end }) {
    if (start && end) {
      fetchDataHandleAuthError(
        `${schema.endpoint}?pagination=false&dateFrom[after]=${
          start.toISOString().split('T')[0]
        }&dateFrom[before]=${end.toISOString().split('T')[0]}`,
        'GET',
        {},
        response => {
          if (response['hydra:member']) {
            let out = []
            response['hydra:member'].map(el => {
              if (el.uuid && el.parent) {
                const dater = new Date(el.dateFrom)
                const title = `${(el.title + ' ').split(' ')[1]} ${
                  el.parent.nativeTitle
                }`

                const newEvent = {
                  id: el.uuid,
                  title: title,
                  start: dater,
                  end: dater,
                  date: new Date(el.dateFrom),
                  color: colorState[el.parent.type],
                  uuid: el.parent.uuid,
                  eventType: el.parent.type,
                  isArchive: el.parent.archive,
                }
                out = [...out, newEvent]
              }
              return []
            })
            setEventsList([...out])
            // setEventsBackgroundList([...out])
          }
        },
        error => {
          if (error.response.title === 'AbortError') {
            return
          }

          notification('error', error.response.detail, error.response.title)
        }
      )
    }
  }

  useEffect(
    () => {
      var dt = new Date()
      var m = dt.getMonth()
      var y = dt.getFullYear()
      var sy = y
      var ey = y
      var sm = m - 1
      var em = m + 1
      if (m === 0) {
        sy = y - 1
        sm = 11
      }
      if (m === 11) {
        ey = y + 1
        em = 0
      }
      sm = sm + 1
      em = em + 1
      var start = new Date(`${sy}-${sm < 10 ? '0' + sm : sm}-21`)
      var end = new Date(`${ey}-${em < 10 ? '0' + em : em}-07`)
      onRangeChange({ start, end })
      return () => {}
    },
    // eslint-disable-next-line
    []
  )

  // tu mozna zmienic kolor wydarzenia/paska
  const eventPropGetter = event => {
    const backgroundColor = event.color
    return { style: { backgroundColor, color: 'black' } }
  }

  // tu mozna zmienic kolor calego dnia
  const dayPropGetter = date => {
    // var item = eventsBackgroundList.filter(
    // el => el.date.getTime() === date.getTime()
    // )
    var color = 'inherit' // null //'#90ee904d'
    // var max = 4
    // if (item.length === 0) {
    // } else if (item.length < max) {
    //   color = colorDay[1]
    // } else if (item.length === max) {
    //   color = colorDay[2]
    // } else if (item.length > max) {
    //   color = colorDay[3]
    // }
    // if (color) {
    const backgroundColor = color
    return { style: { backgroundColor } }
    // }
  }

  const onSelectEvent = calEvent => {
    var p = routesTo[calEvent.eventType][calEvent.isArchive].edit.path.replace(
      ':id',
      calEvent.uuid
    )
    // window.location = p
    history.push(p)
  }

  return (
    <div style={{ padding: 10 }}>
      <Calendar
        localizer={localizer}
        views={['month', 'day']}
        events={eventsList}
        eventPropGetter={eventPropGetter}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '90vh' }}
        onRangeChange={onRangeChange}
        dayPropGetter={dayPropGetter}
        onSelectEvent={onSelectEvent}
        messages={{
          allDay: 'cały dzień',
          next: 'następny',
          previous: 'poprzedni',
          today: 'dziś',
          month: 'miesiąc',
          week: 'tydzień',
          day: 'dzień',
          agenda: 'agenda',
          date: 'Data',
          time: 'Godzina',
          event: 'Event',
        }}
      />

      <table className={classes.legend}>
        <tbody>
          <tr>
            <th rowSpan="2">Legenda:</th>
            {/* <th>Wnioski:</th> */}
            <td>
              <span
                className={classes.legend_span}
                style={{ backgroundColor: colorState['spectacle'] }}
              ></span>{' '}
              Spektakl
            </td>
            <td>
              <span
                className={classes.legend_span}
                style={{ backgroundColor: colorState['event'] }}
              ></span>{' '}
              Wydarzenie
            </td>
            {/* <td>
              <span
                className={classes.legend_span}
                style={{ backgroundColor: colorState[3] }}
              ></span>{' '}
              Zwrócony
            </td>
            <td>
              <span
                className={classes.legend_span}
                style={{ backgroundColor: colorState[1] }}
              ></span>{' '}
              Niepotwierdzony przez użytkownika
            </td> */}
          </tr>
          {/* <tr>
            <th>Kalendarz:</th>
            <td>
              <span
                className={classes.legend_span}
                style={{ backgroundColor: colorDay[1] }}
              ></span>{' '}
              Istnieją wolne terminy
            </td>
            <td>
              <span
                className={classes.legend_span}
                style={{ backgroundColor: colorDay[2] }}
              ></span>{' '}
              Brak wolnych terminów
            </td>
            <td>
              <span
                className={classes.legend_span}
                style={{ backgroundColor: colorDay[3] }}
              ></span>{' '}
              Limit przekroczony
            </td>
          </tr> */}
        </tbody>
      </table>
    </div>
  )
}
