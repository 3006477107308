import photoSchema from './photo'
import mediaSchema from './media'

const schema = {
  endpoint: '/api/crew_items',
  access: 'crewItem',
  resource: {
    definition: '#CrewItem',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          name: {
            description: 'Imię',
          },
          surname: {
            description: 'Nazwisko',
            validate: ['required'],
          },
          text: {
            description: 'Biogram',
            type: 'tinymce',
          },
          job: {
            type: 'string',
            description: 'Stanowisko osoby',
            hint: 'wyswietlane w bloku z dyrekcją',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
          photoDescription: {
            type: 'string',
            description: 'Podpis zdjęcia, np autor zdjęcia',
          },
          slug: {},
          preview: {
            label: 'Podgląd',
            description: '',
            type: 'preview',
            routes: {
              pl: '/aktor/:slug',
              en: '/en/actor/:slug',
            },
          },
        },
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_MEDIA_PHOTO',
        endpoint: photoSchema.endpoint.single,
        endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/api/crew_item_thumbs',
        },
        validate: ['maxSize'],
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
  subresources: {
    images: {
      endpoint: '/api/crew_item_images',
      definition: '#CrewItemImage',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              description: 'Tytuł',
            },
            alt: {
              description: 'Atl',
              type: 'string',
            },
          },
        },
        media: {
          type: 'image',
          description: 'T_GENERAL_MEDIA_PHOTO',
          endpoint: photoSchema.endpoint.single,
          endpointMultiple: photoSchema.endpoint.multiple,
          thumbs: {
            endpoint: '/api/crew_item_image_thumbs',
          },
          validate: ['maxSize'],
        },
      },
    },
    sections: {
      endpoint: '/api/crew_item_sections',
      definition: '#CrewItemSection',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              description: 'Tytuł',
            },
            text: {
              description: 'Tekst',
              type: 'tinymce',
            },

            videowizytowka: {
              type: 'ytWithSht',
              description: 'Videowizytówka',
              mainLabel: 'Videowizytówka',
            },

            ytCode: {
              type: 'string',
              description: 'Kod youtube',
              dataHide: 'videowizytowka-hide',
            },
            signlanguagebutton: {
              type: 'string',
              description: 'Tłumacz migowy',
              dataHide: 'videowizytowka-hide',
            },
            text2: {
              type: 'textarea',
              description: 'Transkrypcja',
              dataHide: 'videowizytowka-hide',
            },
            audiodescriptionfile: {
              type: 'file',
              description: 'Audiodeskrypcja - plik audio',
              endpoint: mediaSchema.endpoint,
              validate: ['maxSize'],
              accept: 'audio/*',
              dataHide: 'videowizytowka-hide',
            },
            audiodescriptionbutton: {
              description: 'Audiodeskrypcja - link',
              hint: 'link, jeśli brak pliku audio, można wpisać',
              type: 'string',
              dataHide: 'videowizytowka-hide',
            },
          },
        },
        autoobsada: {
          description: 'Wylistuj aktualną obsadę w sekcji',
        },
      },
    },
  },
}

export default schema
