const schema = {
  endpoint: '/api/event_terms_bilety/get_termins/{id}',
  endpointAdd: '/api/event_terms_bilety/add_termins/{id}',
  endpointAddAll: '/api/event_terms_bilety/add_all_termins/{id}',
  endpointAddAllTermins: '/api/event_terms_bilety/add_all_termins',
  endpointCronLog: '/api/event_terms_bilety/show_cron_log',
  access: 'event',

  resource: {
    definition: '#EventTerm',
    properties: {
      dateFrom: {
        type: 'datetime',
        description: 'Data i czas terminu',
        validate: ['required'],
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
