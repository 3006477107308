import React, { useCallback, useMemo, useState } from 'react'
import { NotFound } from 'pages'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { translate } from '_helpers/translate'
import schema from '_schema/event'
import itemSchema from '_schema/eventTerm'
import itemSchemaBilety from '_schema/eventTermBilety24'
import routesEvent from 'pages/Event/routes'
import routesSpectacle from 'pages/Event/routesSpectacle'
import routesEventArchive from 'pages/EventArchive/routes'
import routesSpectacleArchive from 'pages/EventArchive/routesSpectacle'
import { OpCell } from './table/OpCell'
import { Title } from 'components/Title'
import { constants } from 'components/table/_state'
import { ImportAllButton } from './table/ImportAllButton'

export const Terms = ({
  schema: definitions,
  type,
  archive = false,
  ...rest
}) => {
  const routes = archive
    ? type === 'event'
      ? routesEventArchive
      : routesSpectacleArchive
    : type === 'event'
    ? routesEvent
    : routesSpectacle

  const { match } = rest

  const parentIri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    parentIri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false
  )

  const itemColumns = useMemo(
    () => [
      {
        header: translate('Data terminu'),
        accessor: `title`,
        width: '73%',
      },
      {
        header: translate('Zaimportowany ID'),
        accessor: `bilety24Id`,
        width: '10%',
      },
      {
        ...operations(`title`, true, true, true, true),
        width: '15%',
      },
    ],
    []
  )

  const itemColumnsBilety = useMemo(
    () => [
      {
        header: translate('Data terminu'),
        accessor: `date`,
        width: '15%',
      },
      {
        header: translate('ID terminu'),
        accessor: `id`,
        width: '10%',
      },
      {
        header: translate('Tytuł terminu'),
        accessor: `title`,
      },
      {
        header: '',
        accessor: `title`,
        Cell: OpCell,
        width: '10%',
      },
    ],
    []
  )

  const [disp, setDisp] = useState(null)
  const getDispatch = useCallback( dispatch => {
    setDisp({ func: dispatch })
  },[])
  const useForeignDispatch = useCallback(() => {
    disp.func({ type: constants.RELOAD })
  }, [disp])

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('Dodaj nowy termin')}
        buttonPath={`${routes().termsNew.path.replace(':id', match.params.id)}`}
        hideButton={false}
      >
        Dodane terminy
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${itemSchema.endpoint}.get`}
        endpoint={itemSchema.endpoint}
        definition={itemSchema.resource.definition}
        storeCollectionId={`${itemSchema.endpoint}?iri[parent]=${parentIri}`}
        defaultFilters={{
          'iri[parent]': parentIri,
        }}
        parameters={[
          {
            name: 'iri[parent]',
            in: 'query',
            type: 'string',
            value: false,
          },
        ]}
        columns={itemColumns}
        customResourceSchema={itemSchema.resource}
        defaultSorters={{ dateFrom: 'desc' }}
        schemaParameters={[
          {
            name: 'order[dateFrom]',
            in: 'query',
            type: 'string',
          },
        ]}
        orderable={false}
        statable={false}
        isRowLinkable={true}
        editPath={routes().termsEdit.path.replace(':pid', match.params.id)}
        autoWidth={false}
        getDispatchCallback={getDispatch}
      />

      {resource.bilety24TitleId > 0 ? (
        <div style={{ marginBottom: 50, marginTop: 50 }}>
          <Title>
            Terminy z Bilety 24, dla podanego w edycji ID:
            {resource.bilety24TitleId}
          </Title>
          <ImportAllButton
            id={resource.bilety24TitleId}
            foreignDispatch={useForeignDispatch}
            uuid={resource.uuid}
          />
          <SchemableCollection
            component={CollectionTable}
            endpoint={itemSchemaBilety.endpoint.replace(
              '{id}',
              resource.bilety24TitleId
            )}
            definition={itemSchemaBilety.resource.definition}
            storeCollectionId={itemSchemaBilety.endpoint.replace(
              '{id}',
              resource.bilety24TitleId
            )}
            columns={itemColumnsBilety}
            customResourceSchema={itemSchemaBilety.resource}
            defaultSorters={{ time: 'desc' }}
            orderable={false}
            statable={false}
            isRowLinkable={false}
            autoWidth={false}
            paginable={false}
            parentResource={resource}
            useForeignDispatch={useForeignDispatch}
          />
        </div>
      ) : null}
    </Paper>
  )
}
