import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import itemSchemaBilety from '_schema/eventTermBilety24'
import { Loader } from 'components/Loader'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { translate } from '_helpers/translate'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'

export const ImportAllTerminsButton = () => {
  const [state, setState] = useState({
    isConfirmOpen: false,
    isFetching: false,
  })

  const onImportClick = () => {
    setState(state => {
      return { ...state, isFetching: true }
    })

    fetchDataHandleAuthError(
      itemSchemaBilety.endpointAddAllTermins,
      'POST',
      {
        body: JSON.stringify({
          // titleId: id,
          // uuid: uuid,
        }),
      },
      response => {
        setState(state => {
          return { ...state, isFetching: false }
        })

        notification(
          'success',
          'Terminy zostały zaimportowane pomyślnie',
          'T_FORM_SUCCESS'
        )
      },
      error => {
        setState(state => {
          return { ...state, isFetching: false }
        })
        notification(
          'error',
          error.response.violations.length
            ? error.response.violations[0].message
            : error.response.detail,
          error.response.title
        )
      }
    )
  }

  const handleOpen = () => {
    setState(state => {
      return { ...state, isConfirmOpen: !state.isConfirmOpen }
    })
  }

  return (
    <div style={{ width: 300 }}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        startIcon={
          state.isFetching ? (
            <Loader align="center" marginBottom={0} />
          ) : (
            <SystemUpdateAltIcon />
          )
        }
        onClick={() => handleOpen()}
        disabled={state.isFetching}
      >
        Synchronizuj terminy z Bilety24
      </Button>

      <Dialog open={state.isConfirmOpen} fullWidth={true} maxWidth="sm">
        <DialogTitle onClose={handleOpen}>
          Import terminów dla wydarzeń i spektakli
        </DialogTitle>
        <DialogContent dividers>
          {state.isFetching && <Loader align="center" marginBottom={15} />}
          <DialogContentText>
            Czy napewno chcesz importować wszystkie terminy z Bilety24 dla
            wszystkich wydarzen i spektakli? Istniejące terminy z taką samą datą
            otrzymają importowany ID.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleOpen}
            disabled={state.isFetching}
            autoFocus
            variant="outlined"
          >
            {translate('T_GENERAL_CANCEL')}
          </Button>
          <Button
            onClick={() => {
              handleOpen()
              onImportClick()
            }}
            disabled={state.isFetching}
            variant="contained"
            color="primary"
          >
            {translate('T_GENERAL_CONFIRM')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
ImportAllTerminsButton.propTypes = {}
