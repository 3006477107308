import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import itemSchemaBilety from '_schema/eventTermBilety24'
import { Loader } from 'components/Loader'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { translate } from '_helpers/translate'
import BugReportIcon from '@material-ui/icons/BugReport'

export const CronLogButton = () => {
  const [state, setState] = useState({
    isConfirmOpen: false,
    isFetching: false,
    isRaportOpen: false,
    log: null
  })

  const onImportClick = () => {
    setState(state => {
      return { ...state, isFetching: true }
    })

    fetchDataHandleAuthError(
      itemSchemaBilety.endpointCronLog,
      'POST',
      {
        body: JSON.stringify({
          // titleId: id,
          // uuid: uuid,
        }),
      },
      response => {
        setState(state => {
          return { ...state, isFetching: false, log: response.log }
        })

        // notification('success', response.log, 'Raport')
        handleOpenReport()

      },
      error => {
        setState(state => {
          return { ...state, isFetching: false }
        })
        notification(
          'error',
          error.response.violations.length
            ? error.response.violations[0].message
            : error.response.detail,
          error.response.title
        )
      }
    )
  }

  const handleOpen = () => {
    setState(state => {
      return { ...state, isConfirmOpen: !state.isConfirmOpen }
    })
  }
  const handleOpenReport = () => {
    setState(state => {
      return { ...state, isRaportOpen: !state.isRaportOpen }
    })
  }

  return (
    <div style={{ width: 300 }}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        startIcon={
          state.isFetching ? (
            <Loader align="center" marginBottom={0} />
          ) : (
            <BugReportIcon />
          )
        }
        onClick={() => handleOpen()}
        disabled={state.isFetching}
      >
        Pokaż oststni raport crona
      </Button>

      <Dialog open={state.isConfirmOpen} fullWidth={true} maxWidth="sm">
        <DialogTitle onClose={handleOpen}>Raport</DialogTitle>
        <DialogContent dividers>
          {state.isFetching && <Loader align="center" marginBottom={15} />}
          <DialogContentText>Czy napewno wyświetlić?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleOpen}
            disabled={state.isFetching}
            autoFocus
            variant="outlined"
          >
            {translate('T_GENERAL_CANCEL')}
          </Button>
          <Button
            onClick={() => {
              handleOpen()
              onImportClick()
            }}
            disabled={state.isFetching}
            variant="contained"
            color="primary"
          >
            {translate('T_GENERAL_CONFIRM')}
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={state.isRaportOpen} fullWidth={true} maxWidth="sm">
        <DialogTitle onClose={handleOpenReport}>Raport</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>{state.log}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleOpenReport}
            disabled={state.isFetching}
            autoFocus
            variant="outlined"
          >
            {translate('T_GENERAL_CANCEL')}
          </Button>

        </DialogActions>
      </Dialog>

    </div>
  )
}
CronLogButton.propTypes = {}
