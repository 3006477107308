import React from 'react'
import { Form } from 'pages'
import schema from '_schema/event'
import routesEvent from './routes'
import routesSpectacle from './routesSpectacle'
import { BlockCollection } from '../../components/block'
import { translate } from '../../_helpers/translate'
import { EmbeddedCollection } from '../../components/embedded'
import { modifyProperties } from './_helper/_modifyproperties'
import { LANGS_IRI } from '_lib/langs'
import { useSelector } from 'react-redux'

export const Edit = ({ schema: definitions, type, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  // const [parentResource, setParentResource] = useState(null)

  const routes = type === 'event' ? routesEvent : routesSpectacle

  const profile = useSelector(state => state.profile)

  return (
    <div>
      <Form
        definitionSchema={definitions[schema.resource.definition]}
        customResourceSchema={schema.resource}
        iri={iri}
        method="PUT"
        url={iri}
        collectionPath={routes().index.path}
        storeCollectionId={schema.endpoint}
        sidebar={true}
        width={'100%'}
        // setParentResource={setParentResource}
        modifyProperties={properties =>
          modifyProperties(profile?.openform, properties, type)
        }
      >
        {type === 'event' ? (
          <BlockCollection
            endpoint={schema.subresources.blockEvent.endpoint}
            pid={match.params.id}
            parentIri={iri}
            definitionSchema={
              definitions[schema.subresources.blockEvent.definition]
            }
            types={schema.subresources.blockEvent.types}
            contextualHint={schema.subresources.blockEvent.contextualHint}
            statable={true}
            key={iri}
          />
        ) : (
          <BlockCollection
            endpoint={schema.subresources.blockSpectacle.endpoint}
            pid={match.params.id}
            parentIri={iri}
            definitionSchema={
              definitions[schema.subresources.blockSpectacle.definition]
            }
            types={schema.subresources.blockSpectacle.types}
            contextualHint={schema.subresources.blockSpectacle.contextualHint}
            statable={true}
            key={iri}
          />
        )}
        {/* is off */}
        {/* <div style={{ marginTop: '30px' }}>
          <EmbeddedCollection
            endpoint={`${schema.subresources.terms.endpoint}?iri[parent]=${iri}&pagination=false`}
            pid={match.params.id}
            parentIri={iri}
            properties={schema.subresources.terms.properties}
            definitionSchema={
              definitions[schema.subresources.terms.definition]
            }
            headerTitle={translate('Terminy')}
            panelTitle={translate('Terminy')}
            titleAccessor={'title'}
            statable={true}
          />
        </div> */}

        {0 && type === 'event' ? null : (
          <div style={{ marginTop: '30px' }}>
            <EmbeddedCollection
              endpoint={`${schema.subresources.images.endpoint}?iri[parent]=${iri}&pagination=false`}
              pid={match.params.id}
              parentIri={iri}
              properties={schema.subresources.images.properties}
              definitionSchema={
                definitions[schema.subresources.images.definition]
              }
              headerTitle={translate('Zdjęcia')}
              panelTitle={translate('Zdjęcie')}
              titleAccessor={`translations.${LANGS_IRI.PL}.title`}
              statable={true}
            />
          </div>
        )}
        {type === 'event' ? null : (
          <div style={{ marginTop: '30px' }}>
            <EmbeddedCollection
              endpoint={`${schema.subresources.videos.endpoint}?iri[parent]=${iri}&pagination=false`}
              pid={match.params.id}
              parentIri={iri}
              properties={schema.subresources.videos.properties}
              definitionSchema={
                definitions[schema.subresources.videos.definition]
              }
              headerTitle={translate('Wideo')}
              panelTitle={translate('Wideo')}
              titleAccessor={`translations.${LANGS_IRI.PL}.title`}
              statable={true}
            />
          </div>
        )}
        {type === 'event' ? null : (
          <div style={{ marginTop: '30px' }}>
            <EmbeddedCollection
              endpoint={`${schema.subresources.pricelists.endpoint}?iri[parent]=${iri}&pagination=false`}
              pid={match.params.id}
              parentIri={iri}
              properties={schema.subresources.pricelists.properties}
              definitionSchema={
                definitions[schema.subresources.pricelists.definition]
              }
              headerTitle={translate('Cennik')}
              panelTitle={translate('')}
              titleAccessor={`translations.${LANGS_IRI.PL}.title`}
              statable={true}
              deletable={false}
              hideAddButton={true}
            />
          </div>
        )}
        {type === 'event' ? null : (
          <div style={{ marginTop: '30px' }}>
            <EmbeddedCollection
              endpoint={`${schema.subresources.avards.endpoint}?iri[parent]=${iri}&pagination=false`}
              pid={match.params.id}
              parentIri={iri}
              properties={schema.subresources.avards.properties}
              definitionSchema={
                definitions[schema.subresources.avards.definition]
              }
              headerTitle={translate('Nagrody')}
              panelTitle={translate('Nagroda')}
              titleAccessor={`translations.${LANGS_IRI.PL}.title`}
              statable={true}
            />
          </div>
        )}
        {type === 'event' ? null : (
          <div style={{ marginTop: '30px' }}>
            <EmbeddedCollection
              endpoint={`${schema.subresources.friends.endpoint}?iri[parent]=${iri}&pagination=false`}
              pid={match.params.id}
              parentIri={iri}
              properties={schema.subresources.friends.properties}
              definitionSchema={
                definitions[schema.subresources.friends.definition]
              }
              headerTitle={translate('Powiązane wydarzenia i spektakle')}
              panelTitle={translate('Powiązane')}
              titleAccessor={'title'}
              statable={true}
            />
          </div>
        )}
      </Form>
    </div>
  )
}
