import React from 'react'
import { Route } from 'react-router-dom'
import EventNoteIcon from '@material-ui/icons/EventNote'
import { Collection } from './Collection'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import schema from '_schema/event'

const basePath = '/event_calendar'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: EventNoteIcon,
      },
    },
    {
      title: 'Kalendarz',
      resourceAccess: schema.access,
      indexPath: basePath,
    }
  )

export default routes
